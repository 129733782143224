<template>
<v-dialog v-model="servicio_dialog" persistent max-width="450" :transition="DialogAnimation" class="rounded-lg" :overlay-opacity="DialogOpacity">
    <v-card>
        <v-card-text class="pa-0 rounded-lg">
            <div v-if="skeleton">
                <v-skeleton-loader type="list-item-three-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line" />
            </div>
            <v-card-title class="pa-0 modal-title" v-if="!skeleton">
                <v-toolbar flat height="56" class="rounded-t-lg">
                    <v-list-item class="px-0">
                        <v-list-item-avatar class="mr-2 rounded-lg"  :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8" />
                        <v-list-item-content>
                            <v-list-item-title class="headline mb-0">
                                <v-toolbar-title class="pa-s0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Servicio {{servicio.numero}}</v-toolbar-title>
                            </v-list-item-title>
                            <v-list-item-subtitle class="font-weight-medium mb-0">
                                <span class="text-capitalize">{{servicio.fecha_nombre}}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-spacer/>
                    <v-menu v-model="card_reset" :close-on-content-click="true" :nudge-width="200" origin="right top" rounded="lg"  left transition="scale-transition" max-width="250" v-if="ResetBtn">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon class="mr-0" v-bind="attrs" v-on="on" >
                                <v-icon size="20">mdi-restart</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item @click="ResetDataServicio()">
                                <v-list-item-icon>
                                    <v-icon size="20">mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Eliminar datos registrados</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!--<v-btn icon @click="CerrarDialogServicio()" class="mr-0">
                        <v-icon size="20">mdi-close</v-icon>
                    </v-btn>-->
                </v-toolbar>
                <v-tabs mobile-breakpoint="300" v-model="vista" height="42" >
                    <v-tabs-slider color=primary></v-tabs-slider>
                    <v-tab class="rounded-lg">Detalle</v-tab>
                    <v-tab class="rounded-lg">
                        <v-badge color="red" dot v-if="servicio.usuario.fu.length">
                            FU
                        </v-badge>
                        <span v-else>FU</span>
                    </v-tab >
                    <v-tab class="rounded-lg">Recorrido</v-tab>
                </v-tabs>
            </v-card-title>
            <v-card-text flat class="pa-0" v-if="!skeleton">
                <v-tabs-items v-model="vista">
                    <v-overlay absolute :opacity=".6" :color="TemaDark?'grey darken-4':'white'" :value="overlay" class="dialog_overlay">
                        <span :class="TemaDark?'white--text':'black--text'">Cargando...</span>
                        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                    </v-overlay>
                    <v-tab-item transition="none" reverse-transition="none">
                        <v-card flat :disabled="DisableEdit">
                            <v-card-text>
                                <v-subheader class="px-0 servicio-sub justify-center">Viaje</v-subheader>
                                <v-card flat>
                                    <v-row align-content="center" justify="center" class="row-horario">
                                        <v-col cols="6" class="py-0 mb-1">
                                            <v-list-item-subtitle class="font-weight-medium mb-0">
                                                <span class="text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{Lugar(Recorrido.inicio)}}</span>
                                            </v-list-item-subtitle>
                                            <p class="text-h6 mb-0" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{servicio.inicio}}<span class="text-h6 mb-0" :class="ColorDifIni? 'red--text':'grey--text'">{{InicioDiferencia}}</span></p>
                                            <v-btn icon color="primary" class="mx-2" @click="ModTiempoDif('sub','ini')">
                                                <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <v-btn icon color="primary" class="mx-2" @click="ModTiempoDif('sum','ini')">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6" class="py-0">
                                            <v-list-item-subtitle class="font-weight-medium mb-0">
                                                <span class="text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{Lugar(Recorrido.termino)}}</span>
                                            </v-list-item-subtitle>
                                            <p class="text-h6 mb-0" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{servicio.termino}}<span class="text-h6 mb-0" :class="ColorDifTer? 'red--text':'grey--text'">{{TerminoDiferencia}}</span></p>
                                            <v-btn icon color="primary" class="mx-2" @click="ModTiempoDif('sub','ter')">
                                                <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <v-btn icon color="primary" class="mx-2" @click="ModTiempoDif('sum','ter')">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-subheader class="px-0 servicio-sub justify-center">Servicios Cercanos</v-subheader>
                                <v-card flat>
                                    <v-row align-content="center" justify="center" class="row-cercanos" style="line-height: 1.2rem;">
                                        <v-col cols="6" class="py-0 pr-0" v-if="servicio.cercanos.anterior.numero">
                                            <p class="my-0 text-subtitle-1 font-weight-medium" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{servicio.cercanos.anterior.numero}}</p>
                                            <v-list-item-subtitle class="font-weight-medium mb-0" v-for="(maquinista, index) in servicio.cercanos.anterior.maquinista" :key="index">
                                                <span class="text-capitalize" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">{{maquinista}}</span>
                                            </v-list-item-subtitle>
                                            <p class="text-caption my-0">Delante</p>
                                        </v-col>
                                        <v-col cols="6" class="py-0 pr-0" v-else>
                                            <p class="my-0 text-subtitle-1 font-weight-medium" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">-</p>
                                            <v-list-item-subtitle class="font-weight-medium mb-0" >
                                                <span class="text-capitalize grey--text text--darken-2">Sin Servicio</span>
                                            </v-list-item-subtitle>
                                            <p class="text-caption my-0">Delante</p>
                                        </v-col>
                                        <v-divider class="my-4" vertical></v-divider>
                                        <v-col cols="6" class="py-0 pl-0" v-if="servicio.cercanos.siguiente.numero" style="line-height: 1.2rem;">
                                            <p class="my-0 text-subtitle-1 font-weight-medium" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{servicio.cercanos.siguiente.numero}}</p>
                                            <v-list-item-subtitle class="font-weight-medium mb-0" v-for="(maquinista, index) in servicio.cercanos.siguiente.maquinista" :key="index">
                                                <span class="text-capitalize" :class="TemaDark?'grey--text text--lighten-2':'grey--text text--darken-2'">{{maquinista}}</span>
                                            </v-list-item-subtitle>
                                            <p class="text-caption my-0">Detras</p>
                                        </v-col>
                                        <v-col cols="6" class="py-0 pr-0" v-else>
                                            <p class="my-0 text-subtitle-1 font-weight-medium" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">-</p>
                                            <v-list-item-subtitle class="font-weight-medium mb-0" >
                                                <span class="text-capitalize grey--text text--darken-2">Sin Servicio</span>
                                            </v-list-item-subtitle>
                                            <p class="text-caption my-0">Detras</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-subheader class="px-0 servicio-sub justify-center">Equipos y Via</v-subheader>
                                <v-card flat>
                                    <v-row>
                                        <v-col cols="9" class="pr-0">
                                            <v-select v-model="servicio.usuario.equipos" :items="lista_equipos" multiple outlined item-text="nombre" item-value="numero" dense @change="EquiposLimite" hide-details class="select_servicio" append-icon="" placeholder="Equipos" :menu-props="{offsetY: false, rounded:'lg', transition:'slide-y-transition'}">
                                                <template v-slot:selection="{ item, index }">
                                                    <v-card class="text-center" width="100%" flat v-if="index == 0 && servicio.usuario.equipos.length == 1">
                                                        <v-chip close @click:close="servicio.usuario.equipos.splice(index, 1)">
                                                            <span>{{ item.nombre }}</span>
                                                        </v-chip>
                                                    </v-card>
                                                    <v-card class="text-right" width="50%" flat v-if="index == 0 && servicio.usuario.equipos.length == 2">
                                                        <v-chip close @click:close="servicio.usuario.equipos.splice(index, 1)">
                                                            <span>{{ item.nombre }}</span>
                                                        </v-chip>
                                                    </v-card>
                                                    <v-card class="text-left" width="50%" flat v-if="index == 1  && servicio.usuario.equipos.length == 2">
                                                        <v-chip close @click:close="servicio.usuario.equipos.splice(index, 1)">
                                                            <span>{{ item.nombre }}</span>
                                                        </v-chip>
                                                    </v-card>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="3" class="pl-1">
                                            <v-select v-model="servicio.usuario.via" :items="Recorrido.anden" item-text="nombre" item-value="valor" outlined dense placeholder="Via" hide-details class="select_servicio" label="Via" append-icon="" :menu-props="{offsetY: false, rounded:'lg', transition:'slide-y-transition'}"/>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-subheader class="px-0 servicio-sub justify-center">Observaciones</v-subheader>
                                <v-textarea outlined label="Observaciones del servicio" dense rows="2" hide-details v-model="servicio.usuario.obs"></v-textarea>
                                <div class="text-center pt-2">
                                    <v-btn depressed color="primary" @click="EditServicio()" v-if="!skeleton" :disabled="DisableEdit || !CantidadEquipos" class="mx-auto" :loading="enviar_btn_loading">Guardar</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item transition="none" reverse-transition="none">
                        <v-card flat max-height="65vh" min-height="50vh" :disabled="DisableEdit">
                            <v-card flat max-height="38vh" min-height="38vh" style="overflow: auto;">
                                <v-card-text class="py-0">
                                    <v-subheader class="px-0 servicio-sub">Frenos de Emergencia ingresados</v-subheader>
                                    <v-list flat class="px-2" v-if="servicio.usuario.fu.length">
                                        <v-list-item class="rounded-lg px-0" v-for="(fu, index) in servicio.usuario.fu" :key="index">
                                            <v-list-item-avatar class="my-0 mr-1" width="35" height="35">
                                                <v-avatar color="grey lighten-4" size="35">
                                                    <v-icon color="red">mdi-alert-circle-outline</v-icon>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title class="my-0 text-subtitle-2 font-weight-medium grey--text text--darken-1">{{TipoFU(fu.tipo)}}
                                                    <v-list-item-subtitle class="font-weight-regular" v-if="fu.obs">{{fu.obs}}</v-list-item-subtitle>
                                                    <v-list-item-subtitle class="font-weight-regular" v-else>Sin Observaciones</v-list-item-subtitle>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn icon @click="servicio.usuario.fu.splice(index,1)">
                                                    <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <v-row v-else>
                                        <v-col cols="12">
                                            <v-card width="100%" height="140" flat class="pt-5">
                                                <v-row justify="center" align-content="center" class="text-center ma-0">
                                                    <v-col cols="12">
                                                        <v-card flat height="120" class="justify-center align-center d-flex flex-column">
                                                            <v-icon size="70" color="grey lighten-1">
                                                                mdi-check-circle-outline
                                                            </v-icon>
                                                            <span class="trenos-title-toolbar grey--text text--lighten-1">Sin FU registrados</span>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card height="160" flat>
                                <v-card-text class="pt-0">
                                    <v-card outlined class="rounded-lg">
                                        <v-card-text class="pb-0">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-select :items="lista_fu" label="Tipo de FU" dense outlined item-text="nombre" item-value="valor" hide-details class="pb-2" v-model="nueva_fu.tipo" :menu-props="{offsetY: false, rounded:'lg', transition:'slide-y-transition'}" />
                                                    <v-text-field v-model="nueva_fu.obs" outlined label="Observaciones" dense hide-details/>
                                                </v-col>
                                            </v-row>
                                            <v-btn text color="primary" class="mx-auto mb-2 mt-1" style="display: flex;" @click="AddFU()" :disabled="nueva_fu.tipo == null">
                                                Agregar
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-card>
                        <v-card flat class="text-center pb-4">
                             <v-btn depressed color="primary" @click="EditServicio()" v-if="!skeleton" :disabled="DisableEdit || !CantidadEquipos" class="mx-auto" :loading="enviar_btn_loading">Guardar</v-btn>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item transition="none" reverse-transition="none">
                        <Recorrido :datos="servicio.dia" :inicio="servicio.inicio" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="CerrarDialogServicio()">
                Cerrar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import dayjs from "dayjs"
export default {
    name: 'Servicio',
    props: ['servicio_id', 'fecha', 'dir'],
    components: {
        Recorrido: () => import('@/components/Recorrido')
    },
    data() {
        return {
            overlay: false,
            servicio_dialog: false,
            vista: 0,
            servicio: {
                usuario: {
                    inicio: 0,
                    termino: 0,
                    obs: '',
                    fu: [],
                    equipos: []
                },
                cercanos: {
                    anterior: 0,
                    siguiente: 0
                },
                dia: []
            },
            lista_equipos: [],
            lista_fu: [
                { nombre: 'ATP', valor: 0},
                { nombre: 'VA', valor: 1},
                { nombre: 'Manipulador', valor: 2 },
                { nombre: 'TP', valor: 3 },
                { nombre: 'Hongo', valor: 4 },
                { nombre: 'Puertas', valor: 5 }
            ],
            via: [
                { nombre: 'V1', valor: 1 }, 
                { nombre: 'V2', valor: 2}, 
                { nombre: 'V3', valor: 3}
            ],
            nueva_fu: {
                tipo: null,
                obs: null
            },
            skeleton: true,
            enviar_btn_loading: false,
            card_reset: false,
            datos_server : []
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'update','sistema']),
        //Muestra la diferencia anotada por el usuario en el tiempo de inicio del servicio.
        InicioDiferencia() {
            let value = 0
            if (this.servicio.usuario.inicio !== 0) {
                Math.sign(this.servicio.usuario.inicio) > 0 ? value = `(+${this.servicio.usuario.inicio})` : value = `(${this.servicio.usuario.inicio})`
            } else {
                value = ''
            }
            return value
        },
        //Muestra la diferencia anotada por el usuario en el tiempo de termino del servicio.
        TerminoDiferencia() {
            let value = 0
            if (this.servicio.usuario.termino !== 0) {
                Math.sign(this.servicio.usuario.termino) > 0 ? value = `(+${this.servicio.usuario.termino})` : value = `(${this.servicio.usuario.termino})`
            } else {
                value = ''
            }
            return value
        },
        //Define el color del nuemero del tiempo de inicio anotado por el usuario
        ColorDifIni() {
            let value = false
            this.servicio.usuario.inicio > 0 ? value = true : value = false
            return value
        },
        //Define el color del numero del tiempo de termino anotado por el usuario.
        ColorDifTer() {
            let value = false
            this.servicio.usuario.termino > 0 ? value = true : value = false
            return value
        },
        Recorrido() {
            let recorrido = {
                inicio: null,
                termino: null
            }
            if (this.dir) {
                recorrido = this.dir.split("-")
                recorrido.inicio = this.dir.split("-")[0].trim()
                recorrido.termino = this.dir.split("-")[1].trim()
                let vias = JSON.parse(JSON.stringify(this.via))
                if (recorrido.inicio == 'pu') vias.pop()
                if (recorrido.inicio == 'eb' || recorrido.inicio == 'sa'){
                    vias.shift()
                    vias.pop()
                } 
                recorrido.anden = vias
            }
            return recorrido
        },
        DisableEdit() {
            let disabled = false
            if (this.fecha > dayjs().format('YYYY-MM-DD')) disabled = true
            return disabled
        },
        CantidadEquipos() {
            let cantidadequipos = false
            if (this.servicio.usuario.equipos && this.servicio.usuario.equipos.length > 0) cantidadequipos = true
            return cantidadequipos
        },
        DialogOpacity() {
            let opacity = .25
            if (this.dialog.turno) opacity = 0
            return opacity
        },
        DialogServicio(){
            return this.dialog.servicio
        },
        EditFU(){
            return false
        },
        ResetBtn(){
            let btn = false
            if(this.servicio.usuario.equipos.length && this.datos_server.length && this.vista == 0) btn = true
            return btn
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(['ShowSnackBar', 'ErrorRequest', 'UpdateComponent','CloseDialog']),
        EquiposLimite() {
            if (this.servicio.usuario.equipos.length > 2) this.servicio.usuario.equipos.pop()
        },
        ModTiempoDif(op, tipo) {
            op == 'sum' ? tipo == 'ini' ? this.servicio.usuario.inicio++ : this.servicio.usuario.termino++ : null
            op == 'sub' ? tipo == 'ini' ? this.servicio.usuario.inicio-- : this.servicio.usuario.termino-- : null
        },
        async GetData(fecha, servicio, show_overlay) {
            if (!fecha && !servicio) {
                console.log('Sin datos', fecha, servicio)
                return
            }
            
            fecha = dayjs(fecha).format('YYYYMMDD')
            this.turno = {}
            if(show_overlay) this.overlay = true
            await this.axios.get(`/servicio/${fecha}/${servicio}`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.servicio = {
                        usuario: {
                            inicio: 0,
                            termino: 0,
                            obs: '',
                            fu: [],
                            equipos: []
                        },
                        cercanos: {
                            anterior: 0,
                            siguiente: 0
                        },
                        dia: []
                    }
                    if(show_overlay) this.overlay = false
                    this.skeleton = false
                    this.servicio = respuesta.data
                    this.servicio.fecha = fecha
                    for (let index = 1; index < 36; index++) {
                        let numero = ('0' + index).slice(-2)
                        let equipo = {
                            nombre: 'XT-' + numero,
                            numero: index
                        }
                        this.lista_equipos.push(equipo)
                    } 
                    this.datos_server = JSON.parse(JSON.stringify(this.servicio.usuario.equipos))
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        TipoFU(tipo) {
            let nombreFU = null
            switch (tipo) {
                case 0:
                    nombreFU = 'ATP'
                    break
                case 1:
                    nombreFU = 'VA'
                    break
                case 2:
                    nombreFU = 'Manipulador'
                    break
                case 3:
                    nombreFU = 'TP'
                    break
                case 4:
                    nombreFU = 'Hongo'
                    break
                case 5:
                    nombreFU = 'Puertas'
                    break
                default:
                    break
            }
            return nombreFU
        },
        AddFU() {
            this.servicio.usuario.fu.push({
                obs: this.nueva_fu.obs,
                tipo: this.nueva_fu.tipo
            })
            this.nueva_fu = {
                tipo: null,
                obs: null
            }
        },
        async EditServicio() {
            this.overlay = true
            await this.axios.post(`/servicio/${this.fecha}/${this.servicio_id}/edit`, this.servicio.usuario, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.update.turno = true
                        this.update.calendario = true
                        this.enviar_btn_loading = false
                        this.GetData(this.fecha, this.servicio_id, true)
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.overlay = false
            })
        },
        async ResetDataServicio() {
            this.enviar_btn_loading = true
            await this.axios.get(`/servicio/${this.fecha}/${this.servicio_id}/reset`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.GetData(this.fecha, this.servicio_id)
                        this.update.turno = true
                        this.update.calendario = true
                        this.enviar_btn_loading = false
                        this.datos_server = false
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.enviar_btn_loading = false
            })
        },
        CerrarDialogServicio() {
            this.servicio_dialog = false
            this.CloseDialog('servicio')
        },
        Lugar(lugar) {
            let nombre_lugar
            switch (lugar) {
                case 'pu':
                    nombre_lugar = 'Puerto'
                    break
                case 'li':
                    nombre_lugar = 'Limache'
                    break
                case 'sa':
                    nombre_lugar = 'S.Aldea'
                    break
                case 'eb':
                    nombre_lugar = 'El Belloto'
                    break
                case 'eb/sa':
                    nombre_lugar = 'EB/SA'
                    break
            }
            return nombre_lugar
        },
    },
    mounted() {
        this.vista = 0
        if (this.fecha && this.servicio_id) {
            this.GetData(this.fecha, this.servicio_id, false)
        }
    },
    watch: {
        DialogServicio: function (val){
            if(val && this.servicio_id && this.fecha){
                this.skeleton = true
                this.vista = 0
                this.servicio_dialog = true
                this.GetData(this.fecha, this.servicio_id, false)
            }
            if(!val) this.CerrarDialogServicio()
        }
    }
}
</script>

<style scoped>
.row-horario {
    text-align: center;
}

.row-cercanos {
    text-align: center;
}

.viaje-recorrido {
    line-height: .5rem !important;
}

.cercano-maq {
    line-height: 1.25rem !important;
}

.servicio-sub {
    height: 42px !important;
}

.fecha-detalle {
    line-height: 1rem !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 14px;
}

.v-list-item {
    min-height: 42px;
}

.servicio-activo {
    background-color: var(--v-primary-base);
}

.modal-title {
    position: sticky;
    top: 0;
    z-index: 10;
}

.v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 6px;
}

.select_servicio {
    justify-content: center !important;
}

.v-select__selections {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    line-height: 18px;
    max-width: 80%;
    min-width: 0;
}
</style>
